import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";

function Session() {

  const [session, setSession] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      if (searchParams.get('session_id')) {
        const localSession = await fetch(env.AUTH0_API_SERVER+"session/"+searchParams.get('session_id'), {
          headers: {
            "Content-Type":"application/json",
          },
        }).then((res) => res.json());
        setSession(localSession);
      }
      setLoading(false);
    };
    initData();
  }, [searchParams])

  return (
    <section id="session">
      {!loading && session?.status === 'complete' && <div className="affiliation-section">
        <p>{t('thanks_purchase', {email: session.customer_details.email})}</p>
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Session;
