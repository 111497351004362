import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import env from "react-dotenv";
import App from './App';
import './index.css';
import { Auth0Provider } from "@auth0/auth0-react";

i18n.use(Backend).use(initReactI18next).init({
  debug: !!env.DEV,
  fallbackLng: env.DEFAULT_LANG,
  supportedLngs: ['en', 'it'],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user profile",
      }}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
