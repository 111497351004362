import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { ButtonsContainer, GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

function Public() {

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('redirect')) {
      //window.location.href = searchParams.get('redirect');
    }
  }, [searchParams])

  return (
    <section id="public">
      <div className="affiliation-section">
        <div className="mb-5">
          <p>{t('app_download')}</p>
        </div>
        <ButtonsContainer direction="column">
          <AppStoreButton url="https://apps.apple.com/it/app/moonycard/id6478211367" theme="dark" width="200px" height="60px" />
          <GooglePlayButton url="https://play.google.com/store/apps/details?id=com.moonycard.moonycard" theme="dark" width="200px" height="60px" />
        </ButtonsContainer>
      </div>
    </section>
  );
}

export default Public;
