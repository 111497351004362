import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(env.STRIPE_KEY);

function Checkout() {

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [secret, setSecret] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const startSession = async () => {
    setLoading(true);
    const session = await fetch(env.AUTH0_API_SERVER+"session", {
      headers: {
        "Content-Type":"application/json",
      },
      method: 'post',
      body: JSON.stringify({
        product: product.id,
        store: window.sessionStorage.getItem('store'),
        return_url: window.location.origin+'/session?session_id={CHECKOUT_SESSION_ID}',
      }),
    }).then((res) => res.json());
    setSecret(session.client_secret);
    setProduct(null);
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const localProducts = await fetch(env.AUTH0_API_SERVER+"products", {
        headers: {
          "Content-Type":"application/json",
        },
      }).then((res) => res.json());
      setProducts(localProducts.sort((a, b) => a.price - b.price).filter((x) => x.frequency === 'one_shot'));
      setLoading(false);
    };
    initData();
  }, [auth])

  return (
    <section id="tokens">
      {!loading && !secret && products.length > 0 && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('buy')}</h2>
        {products.map((v, k) => (
          <div key={k} className={v.id === product?.id ? 'affiliation-product-selected' : 'affiliation-product'} onClick={() => setProduct(v)}>
            <h4>{v.description}</h4>
            <h2>{(v.price*1).toFixed(2)} €</h2>
          </div>
        ))}
        <div className="mt-3">
          <button disabled={!product} className="btn btn-lg item-button" onClick={() => startSession()}>Conferma</button>
        </div>
      </div>}
      {!loading && secret && <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret: secret}}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Checkout;
