import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

function Notifications() {

  const [cost, setCost] = useState(0);
  const [page, setPage] = useState(1);
  const [range, setRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [notificationCreate, setNotificationCreate] = useState(false);
  const [validUrl, setValidUrl] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const [store, setStore, userdata] = useOutletContext();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: cost > 0 ? t('operation_cost', {cost: cost}) : '',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const sendNotification = async () => {
    setLoading(true);
    setNotificationCreate(false);
    setValidUrl(true);
    if (url !== '' && !/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(url)) {
      setValidUrl(false);
      setLoading(false);
    } else {
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      await fetch(env.AUTH0_API_SERVER+"send-notification", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          store: window.sessionStorage.getItem('store'),
          title: title,
          body: body,
          url: url,
          store_name: store.name,
        }),
      }).then((res) => res.json());
      const localStore = {...store};
      localStore.tokens -= cost;
      setStore(localStore);
      setNotificationCreate(true);
      setTitle('');
      setBody('');
      setUrl('');
      setLoading(false);
    }
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store') && store?.notifications) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const priceList = await fetch(env.AUTH0_API_SERVER+"price-lists/notification", {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setCost(priceList.cost);
        const notifications = await fetch(env.AUTH0_API_SERVER+"notifications/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        const rowsPerPage = isMobile ? 5 : 10;
        const localRange = [];
        for (let i = 1; i <= Math.ceil(notifications.length / rowsPerPage); i++) {
          localRange.push(i);
        }
        setRange([...localRange]);
        const localSlice = notifications.slice((page - 1) * rowsPerPage, page * rowsPerPage);
        setSlice([...localSlice]);
        if (localSlice.length < 1 && page !== 1) {
          setPage(page - 1);
        }
        setLoading(false);
      }
    };
    initData();
  }, [auth, store, page])

  return (
    <section id="notifications">
      {!loading && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('notifications')}</h2>
        <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('text')}</th>
                <th scope="col">{t('date')}</th>
              </tr>
            </thead>
            {slice.length > 0 && <tbody>
              {slice.map((el, k) => (
                <tr key={k}>
                  <td>
                    <h5>{el.title}</h5>
                    <span>{el.body} </span>
                    {el.url && <a href={el.url} target="_blank" rel="noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                      </svg>
                    </a>}
                  </td>
                  <td style={{width: '200px',}}>
                    <span>{new Date(el.date).toDateString()}</span>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>
        </div>
        {slice.length === 0 && <span>{t('no_notifications')}</span>}
        <div>
          {range.map((el, index) => (
            (page === el || page === el-1 || page === el+1 || index === 0 || index === range.length-1) && <button key={index} className={page === el ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => setPage(el)}>{(page === el+1 && index !== 0) || (page === el-1 && index !== range.length-1) ? '...' : el}</button>
          ))}
        </div>
      </div>}
      {!loading && userdata.memberships?.find((v) => v.store === window.sessionStorage.getItem('store'))?.role === 'admin' && store?.tokens >= cost && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('send_notification')}</h2>
        {cost > 0 && <p>{t('operation_cost', {cost: cost})}</p>}
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('title')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('text')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={body} onChange={(e) => setBody(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('url')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
              </td>
            </tr>
          </tbody>
        </table>
        {!validUrl && <small>{t('valid_url')}</small>}
        <div className="mt-3">
          <button disabled={!title || !body} className="btn btn-lg item-button" onClick={() => confirm(() => sendNotification())}>{t('confirm')}</button>
        </div>
        {notificationCreate && <small>{t('created_notification')}</small>}
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Notifications;
